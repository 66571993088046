<template>
  <HXContentCard title="Lista de Cargos">
    <div class="text-right p-4">
      <div class="d-flex justify-content-end">
        <vs-button
          v-if="usuario.rol.nivel == 4 || usuario.rol.nivel == 3"
          icon
          animation-type="vertical"
          @click="$router.push('cargos_agregar')"
        >
          <i class="fas fa-plus-circle px-1"></i>
          Agregar cargo
          <template #animate>
            <i class="fas fa-plus-circle"></i>
          </template>
        </vs-button>
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_cargos"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre cargo</th>
            <th>Nivel cargo</th>
            <th>Cargo superior</th>
            <th>Área</th>
            <th>Sucursal</th>
            <th>Estado</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaItems" :key="item.id">
            <td>
              <strong>
                {{ index + 1 }}
              </strong>
            </td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.tipoCargo.descripcion }}</td>
            <td
              v-if="
                item.cargoDependienciaId != null &&
                item.cargoDependienciaId != ''
              "
            >
              <template v-for="internalItem of listaItems">
                <span
                  v-if="internalItem.id == item.cargoDependienciaId"
                  :key="internalItem.id"
                >
                  {{ internalItem.nombre }}
                </span>
              </template>
              {{ item.tipoCargo.descripcion }}
            </td>
            <td v-else>--</td>
            <td>{{ item.area.descripcion }}</td>
            <td>
              {{ item.sucursal.descripcion }}
            </td>
            <td>
              <div
                @click.prevent="toggleState(item.id)"
                class="pointer"
                v-if="item.estado != null"
              >
                <span v-if="item.estado == 1" class="badge badge-success"
                  >Activo</span
                >
                <span v-else class="badge badge-danger">Inactivo</span>
              </div>
              <div v-else>
                <span>
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
              </div>
            </td>
            <td style="width: 150px !important">
              <ButtonGroup :Id="item.id" :data="dataIcons"></ButtonGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";

const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      row: {
        titulo: "",
        tipoCompetencia: {
          descripcion: "",
        },
        descripcion: "",
      },
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar cargos",
          title: "",
          value: () => 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Cargos_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          type: "primary",
          tooltip: "Perfil de cargos",
          title: "",
          value: () => 0,
          iconName: "address-card",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Cargos_perfil/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_cargos").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async toggleState(id) {
      this.listaItems = this.listaItems.map((item) => {
        if (item.id === id) {
          item.estado = null;
        }
        return item;
      });
      const response = await this.$store.getters.fetchPut({
        path: `cargo/HiddenToggle/${id}`,
      });
      const result = await response.json();
      this.listaItems = this.listaItems.map((item) => {
        if (item.id === id) {
          item.estado = result.estado;
        }
        return item;
      });
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Competencia/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);

      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "Cargo/TodosLosCargosPorEmpresa/" + this.usuario.empresa.id,
        });
        console.log("get cargos... ", res);
        if (res.length > 0) {
          this.listaItems = new Array(...res);
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style lang="scss"></style>
